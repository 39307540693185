<template>
  <div class="auth-page">
    <!-- background image; only shown on computer screens + -->
    <v-img
      v-show="!$vuetify.breakpoint.xsOnly"
      width="650"
      class="background-image"
      src="@/assets/helper/alinotec_cameras.webp"
    />

    <div class="auth-card center elevation-1">
      <v-fade-transition>
        <div
          v-show="loadingCard"
          style="
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 100;
          "
        >
          <v-progress-circular
            indeterminate
            width="3"
            size="40"
            color="primary"
            class="center"
          ></v-progress-circular>
        </div>
      </v-fade-transition>
      <v-fade-transition>
        <div
          v-show="errorCard && !loadingCard"
          style="
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.95);
            z-index: 100;
          "
        >
          <slot class="center" name="error" />
        </div>
      </v-fade-transition>

      <!-- Alinocam Logo - centered on top of auth-card with 25px margin -->
      <div class="logo-container">
        <img
          preload
          width="260"
          height="54"
          class="logo"
          v-on:click="$router.push({name: 'login'})"
          :src="getImgUrl()"
        />
      </div>

      <div class="pl-5 pr-5 pb-5">
        <slot name="input" />

        <div
          style="
            width: 100%;
            height: 75px;
            background-color: transparent;
            position: relative;
          "
        >
          <div
            v-if="success == true"
            class="mb-2 pa-1"
            style="
              font-size: 14px;
              width: 100%;
              text-align: left;
              border-radius: 10px;
              position: absolute;
              bottom: 0px;
            "
          >
            <v-row class="ma-0" align="start">
              <v-icon
                color="success"
                class="mr-2"
                style="margin-top: 2px"
                size="18"
                >mdi-checkbox-marked-circle
              </v-icon>
              <div style="max-width: calc(100% - 50px)">
                <b
                  style="padding-bottom: 1px; font-size: 14px"
                  class="grey--text"
                  >{{ successText }}</b
                >
              </div>
            </v-row>
          </div>
          <div
            v-if="error != null && error.status == true"
            class="mb-2 pa-1"
            style="
              font-size: 14px;
              width: 100%;
              text-align: left;
              border-radius: 10px;
              position: absolute;
              bottom: 0px;
            "
          >
            <v-row class="ma-0" align="start">
              <v-icon
                color="primary"
                class="mr-2 font-weight-medium"
                style="margin-top: 3px"
                size="16"
                >mdi-alert-circle</v-icon
              >
              <div style="max-width: calc(100% - 50px)">
                <b
                  style="padding-bottom: 1px; font-size: 14px"
                  class="primary--text"
                  >{{ error.text }}</b
                >
              </div>
            </v-row>
          </div>
        </div>
        <div>
          <TextButton
            id="recaptcha-container"
            :loading="loading"
            :disabled="disabled"
            v-on:click="$emit('click')"
            buttonColor="primary"
            block
            :text="button"
          />

          <slot name="bottom" />
        </div>
      </div>
    </div>

    <div class="terms-container">
      <v-row class="ma-0 mb-1" align="center" justify="center">
        <a :href="$t('auth.privacy.url')" class="links"
          ><span class="links">{{ $t('auth.privacy.text') }}</span></a
        >
        <a :href="$t('auth.imprint.url')" class="links"
          ><span class="links ml-5">{{ $t('auth.imprint.text') }}</span></a
        >

        <div class="ml-7">
          <span v-on:click="changeLanguage('de')">
            <country-flag
              class="mt-3 mb-1 flag"
              :style="
                $route.params.lang == 'de'
                  ? 'pointer-events: none'
                  : 'opacity: .3'
              "
              country="de"
              size="normal"
            >
            </country-flag>
          </span>
          <!-- <span v-on:click="changeLanguage('fr')"><country-flag class="mt-3 mb-1 flag"  :style="$route.params.lang == 'fr' ? 'pointer-events: none' : 'opacity: .3'" country='fr' size='normal'></country-flag>
          </span> -->
          <span v-on:click="changeLanguage('en')">
            <country-flag
              class="mt-3 mb-1 flag"
              :style="
                $route.params.lang == 'en'
                  ? 'pointer-events: none'
                  : 'opacity: .3'
              "
              country="gb"
              size="normal"
            >
            </country-flag>
          </span>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';
import CountryFlag from 'vue-country-flag';

export default {
  name: 'AuthPageTemplate',

  props: {
    errorCard: {
      type: Boolean,
      default: false,
    },

    loadingCard: {
      type: Boolean,
      default: false,
    },

    success: {
      type: Boolean,
      default: false,
    },
    successText: {
      type: String,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    error: {
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
  },
  components: {
    TextButton,
    CountryFlag,
  },

  created() {
    this.$auth.logout();
  },

  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;

      this.$vuetify.lang.current = lang // apply to change language for Vuetify compoennts

      this.$router.replace({ params: { lang: lang } });
    },

    getImgUrl() {
      var images = require.context(
        '../../assets/logo/alinocam/',
        false,
        /\.png$/,
      );

      if (images == null) return;
      else return images('./text_large.png');
    },
  },
};
</script>

<style scoped>
.auth-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.logo-container {
  width: 100%;
  text-align: center;
}

.logo {
  user-select: none;
  user-drag: none;
  margin-top: 25px;
  margin-bottom: 25px;
}

.auth-card {
  /* font-family: 'Lato', sans-serif!important; */
  display: block;
  position: relative;
  user-select: none;
  border-radius: 10px;
  width: 400px;
  background-color: white;

  overflow: hidden;
  /* top: 50%; left: 50%;
    transform: translate(-50%, -50%) */
}

.terms-container {
  position: absolute;
  bottom: 10px;
  max-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.links {
  user-select: none;
  font-size: 14px;
  cursor: pointer !important;
  text-decoration: none;
  color: black;
}

.links:hover {
  color: var(--v-primary-base);
}

.flag {
  margin-left: 1px !important;
  border-radius: 10px;
  transition: all 100ms linear;
}

.flag:hover {
  transform: scale(0.55);
  cursor: pointer;
  opacity: 1 !important;
  transition: all 100ms linear;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background-image {
  position: absolute;
  right: -50px;
  bottom: 0px;
  opacity: 0.2;
}
</style>
